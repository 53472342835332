.contact-container {
    padding: 3rem 5vw;
    background-color: #29ABE2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 3rem;
}

.contact-container img {
    margin: 1rem 0rem;
}

.contact-container div:nth-child(2) {
    text-align: center;
}

.contact-container h1 {
    font-size: 5vw;
}

.contact-container p {
    margin: 1rem auto;
}

.contact-container button {
    border: none;
    padding: 0px;
    margin: 0px;
    background-color: inherit;
}

input,
textarea,
input:focus,
textarea:focus {
    width: 70%;
    padding: 12px;
    border: 0.15rem solid #000;
    background-color: #29ABE2;
    color: black;
    margin: 0.5rem 0rem;
    font-size: 1.1rem;
    outline: none !important;
    box-shadow: 0 0 10px #719ECE;
}

textarea {
    resize: none;
    height: 150px;
}

textarea::placeholder,
input::placeholder {
    color: black;
}

.contact-container div:last-child div {
    display: flex;
    justify-content: right;
    margin-right: 10%;
    height: 7rem;
}

@media only screen and (max-width: 1150px) {

    .contact-container {
        display: block;
    }

    .contact-container>div:first-child>img {
        display: none;
    }

    input,
    textarea,
    input:focus,
    textarea:focus {
        width: 80%;
    }

}

@media only screen and (max-width: 600px) {
    .contact-container div:last-child div {
        height: 5.5rem;
        margin-right: 0px;
    }

    .contact-container div:first-child h1 {
        font-size: 12vw;
    }

    .contact-container {
        display: block;
    }

    .contact-container>div>img {
        width: 100%;

    }

    input,
    textarea,
    input:focus,
    textarea:focus {
        width: 90%;
    }

}

.submit-button {
    cursor: pointer;
}