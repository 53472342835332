
*{
  padding:0px;
  margin:0px;
}

body{
  background-color:#161625;
  font-size: 1.1rem;
}

p{
  animation: fadeInAnimation ease 2.5s
}

button{
  animation: fadeInAnimation ease 2.5s

}




@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@media only screen and (max-width: 1145px){
  .grid-container div:nth-child(3){
    display: none;
  }
  .grid-container{
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
}

