
.carousel{
    margin-bottom: 7rem;

}

.review-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    color: #29ABE2;
}

.review-container div:nth-child(1) {
    display: flex;
    justify-content: center;
}

.review-container>div:nth-child(2)>p:nth-child(2) {
    margin: 2rem 0rem;
    font-size: 1.2rem;
    font-weight: lighter;
    width: 85%;
}

.review-container div:nth-child(3)>p {
    margin: 0.5rem;
}

.review-container>div>h3 {
    font-size: 5vw;
}

@media only screen and (max-width: 1150px) {

    .review-container {
        grid-template-columns: 1fr 1.5fr;
    }

    .review-container>div:first-child>img {
        width: 100%;
        height: auto;
    }

    .review-container>div:nth-child(2)>p:nth-child(2) {
        margin: 2rem 0rem;
        font-size: 1.4rem;
        font-weight: bold;
        width: 85%;
    }

}


@media only screen and (max-width: 600px) {
    .review-container {
        display: block;
    }

    .review-container div:nth-child(2)>h3 {
        display: none;
    }

    .review-container>div:nth-child(2)>p:nth-child(2) {
        margin: 1.25rem 0rem;
        text-align: center;
        width: 95%;
        font-size: 1.5rem;
    }

    .review-container>div:nth-child(2)>div {
        font-size: 0.8rem;
    }

    .review-container>div>img {
        display: block;
        width: 50vw;
        margin: 0px;
        padding: 0px;
    }
}

.customer-logo {
    max-height: 50%;
    max-width: 70%;
    margin-top: 5rem;
}