

.service-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin:7rem 0rem;
    color: #29ABE2;
    gap:4rem;
}

.service-container p{
    margin: 1rem 0rem;
}

.service-container > div > h3{
    font-size: 5vw;
}

.service-box{
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid #29ABE2;
    margin: 1.2rem 0rem;
    padding:1rem;
    gap:2rem;
}

.service-box:nth-child(1){
    background-color: #29ABE2;
    color: #161625;
}

.service-box div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-box h5{
    font-size: 1.5rem;
}

@media only screen and (max-width: 1150px){

    .service-container{
        display: block;
    }

    .services{
        margin-inline: 2.5vw;
    }

    .service-container > div:first-child > img{
        display: none;
    }



}


@media only screen and (max-width: 600px){

    .service-container{
        display: block;
        margin:5rem 0rem;
    }
    
    .service-container > div > h3{
        font-size: 12vw;
    }
    .service-container > div > img{
        display: none;
    }

    .services{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .service-box{
        width: auto;
        margin: 0.65rem 0rem;
        grid-template-columns: 1fr 3fr;
    }
}