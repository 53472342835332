header {
  border-bottom: solid 0.1rem #29ABE2;
  padding: 0rem 5vw;
  display: flex;
  justify-content: space-between;

}

.logo-image {
  height: 12vh;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
  min-height: 100px;
  animation: fadeInAnimation ease 2.5s
}

header div:nth-child(2) {
  display: flex;
  align-items: center;
}


@media only screen and (max-width: 600px) {
  header {
    width: auto;
    display: flex;
    justify-content: center;
  }

  .logo-image {
    height: 15vh;
  }

  .navigation {
    display: none;
  }

}