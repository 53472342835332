
.about-us{
    margin: 7rem 0rem;
    color: #29ABE2 !important;
    width: 70%;
}

.about-us > h1{
    font-size: 5vw;
    color: #29ABE2;

}


.about-us > p{
    margin: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}
