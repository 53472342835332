
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


body {
  margin: 0;
  font-family: 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Roboto',
    sans-serif;
}
