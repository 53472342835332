.navigation li{
    display: inline;
    list-style-type: none;
    margin: 0rem 1.5rem;
    font-size: 1.2rem;
    color: #29ABE2;
    font-weight: 500;
    font-weight: bold;
  }
  
  .navigation li a{
    text-decoration: none;
    color: inherit;
    animation: fadeInAnimation ease 2.5s
  }
  
  .navigation li:last-child{
    margin-right: 0rem;
  }
  
  .container{
    margin-inline: 5vw;
  }
  
  .banner{
    color:#29ABE2;  
  }
  
  .banner h1{
    font-size: 7vw;
    min-width: 30%;
    margin: 1.5rem 0rem;
    margin-top:3rem;
    animation: fadeInAnimation ease 2.5s
  }
  
  .grid-container{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }
  
  .grid-container div{
    margin-inline: 1rem;
  }
  
  
  
  .grid-container div:nth-child(3){
    margin-top: auto;
  }
  .grid-container div:nth-child(3) p{
    margin-bottom: 1rem;
  }
  
  .grid-container > div > button{
    display: block;
    background-color: inherit;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    margin: 2rem 0rem;
    border: solid 0.1rem #29ABE2;
    font-size: 1.2rem;
    color: #29ABE2;
    font-weight: bold;
    border-radius: 2rem;
    width:65%;
    min-width: 250px;
  
  }
  .grid-container > div > button > a{
    text-decoration: none;
    color: #29ABE2;

  }
  
  .banner-arrow{
    width: 6rem;
    animation: fadeInAnimation ease 2.5s
  }


@media only screen and (max-width: 600px){
  .container{
    margin-inline: 3.5vw;
  }


  .banner h1{
    font-size: 15vw;
  }
  .grid-container{
    display: block;
    padding-inline: 0px;
    margin-inline: 0px;
  }

  .grid-container p{
    font-size: 1.5rem;
  }
  
  .grid-container div{
    padding-inline: 0px;
    margin-inline: 0px;
  }

  .grid-container div:nth-child(2){
    display: flex;
    justify-content: center;

  }

  .grid-container div:nth-child(2) img{
    display: none;
  }
  

  }