

.footer{
    margin: 2.5rem 0rem 4rem 0rem;
    color: #29ABE2;
    font-size: 1rem;
}

.footer > div{
    width: 50vw;
}

.footer > div > p:last-child{
    margin-top:1rem;
}

@media only screen and (max-width: 1150px){
    
    .footer > div{
        width: 75%;
    }

}

@media only screen and (max-width: 600px){
    
    .footer > div{
        width: auto;
    }

}